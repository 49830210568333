import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import classNames from 'classnames/bind';

import styles from './NoteText.module.css';

function NoteText({ note, truncated }) {
  return (
    <p
      className={classNames({
        [styles.truncated]: truncated,
      })}
    >
      <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
        {note}
      </ReactMarkdown>
    </p>
  );
}

NoteText.propTypes = {
  note: PropTypes.string.isRequired,
};

export default NoteText;
