const NETWORK_STATUS_CHANGED = 'CONNECTIVITY/NETWORK_STATUS_CHANGED';
const WEBSOCKET_STATUS_CHANGED = 'CONNECTIVITY/WEBSOCKET_STATUS_CHANGED';
const TAG_DATA_CHANGED = 'CONNECTIVITY/TAG_DATA_CHANGED';
const NOTE_DATA_CHANGED = 'CONNECTIVITY/NOTE_DATA_CHANGED';

export const networkStatusChanged = online => ({
  type: NETWORK_STATUS_CHANGED,
  online,
});

export const websocketStatusChanged = connected => ({
  type: WEBSOCKET_STATUS_CHANGED,
  connected,
});

export const tagDataChanged = payload => ({
  type: TAG_DATA_CHANGED,
  payload,
});

export const noteDataChanged = payload => ({
  type: NOTE_DATA_CHANGED,
  payload,
});

export const getWebsocketConnected = state =>
  state.connectivity.websocketConnected;

export const getTagData = state => state.connectivity.tagData;

export const getNoteData = state => state.connectivity.noteData;

const initialState = {
  online: true,
  websocketConnected: false,
  tagData: {},
  noteData: {},
};

export default function connectivityReducer(state = initialState, action) {
  switch (action.type) {
    case NETWORK_STATUS_CHANGED: {
      return {
        ...state,
        online: action.online,
      };
    }
    case WEBSOCKET_STATUS_CHANGED: {
      return {
        ...state,
        websocketConnected: action.connected,
      };
    }
    case TAG_DATA_CHANGED: {
      return {
        ...state,
        tagData: action.payload,
      };
    }
    case NOTE_DATA_CHANGED: {
      return {
        ...state,
        noteData: action.payload,
      };
    }
    default:
      return state;
  }
}
