const notificationType = {
  BandwidthUpdatedEvent: 'BandwidthUpdatedEvent',
  ChannelAvatarChanged: 'ChannelAvatarChanged',
  ChannelCollaboratorAdded: 'ChannelCollaboratorAdded',
  ChannelCollaboratorRemoved: 'ChannelCollaboratorRemoved',
  ChannelCollaboratorStatusChanged: 'ChannelCollaboratorStatusChanged',
  ChannelComment: 'ChannelComment',
  ChannelCreated: 'ChannelCreated',
  ChannelDeleteResource: 'ChannelDeleteResource',
  ChannelDeleted: 'ChannelDeleted',
  ChannelFilteredEvent: 'ChannelFilteredEvent',
  ChannelInvitation: 'ChannelInvitation',
  ChannelLinkRenamed: 'ChannelLinkRenamed',
  ChannelLockChanged: 'ChannelLockChanged',
  ChannelNewResource: 'ChannelNewResource',
  ChannelNotificationCountUpdate: 'ChannelNotificationCountUpdate',
  ChannelRenamed: 'ChannelRenamed',
  ChannelResourceChanged: 'ChannelResourceChanged',
  ChannelResourceDownloaded: 'ChannelResourceDownloaded',
  ChannelResourceLinked: 'ChannelResourceLinked',
  ChannelResourceUnlinked: 'ChannelResourceUnlinked',
  ChannelResourceWritabilityChanged: 'ChannelResourceWritabilityChanged',
  DeleteNotification: 'DeleteNotification',
  EditNotification: 'EditNotification',
  GRevokedWorkspaceAccess_DEPRECATED: 'GRevokedWorkspaceAccess',
  GWorkspaceCollaboratorAdded_DEPRECATED: 'GWorkspaceCollaboratorAdded',
  GWorkspaceCollaboratorRemoved_DEPRECATED: 'GWorkspaceCollaboratorRemoved',
  GWorkspaceCollaboratorStatusChanged_DEPRECATED:
    'GWorkspaceCollaboratorStatusChanged',
  GWorkspaceCreated_DEPRECATED: 'GWorkspaceCreated',
  GWorkspaceDeleted_DEPRECATED: 'GWorkspaceDeleted',
  GWorkspaceRenamed_DEPRECATED: 'GWorkspaceRenamed',
  GWorkspaceResourceWritabilityChanged_DEPRECATED:
    'GWorkspaceResourceWritabilityChanged',
  GWorkspaceReward: 'GWorkspaceReward',
  OnlineStatusUpdated: 'OnlineStatusUpdated',
  PresenceChangedEvent: 'PresenceChangedEvent',
  QuotaUpdated: 'QuotaUpdated',
  ReadStateChanged: 'ReadStateChanged',
  ReminderAdded: 'ReminderAdded',
  ReminderEdited: 'ReminderEdited',
  ReminderDeleted: 'ReminderDeleted',
  ReminderFired: 'ReminderFired',
  RepositoryCreated: 'RepositoryCreated',
  ResourceComment: 'ResourceComment',
  ResourceDeleteNotification: 'ResourceDeleteNotification',
  ResourceDeleted: 'ResourceDeleted',
  ResourceEditNotification: 'ResourceEditNotification',
  ResourceNew: 'ResourceNew',
  RevokedChannelAccess: 'RevokedChannelAccess',
  ShareTaskEvent: 'ShareTaskEvent',
  SpaceComment_DEPRECATED: 'SpaceComment',
  SpaceInvitation_DEPRECATED: 'SpaceInvitation',
  StarredNotification: 'StarredNotification',
  StarredResource: 'StarredResource',
  StarredLinkedItem: 'StarredLinkedItem',
  TaskEvent: 'TaskEvent',
  TypingStarted: 'TypingStarted',
  TypingStopped: 'TypingStopped',
  UserAvatarChanged: 'UserAvatarChanged',
  WorkspaceCreated: 'WorkspaceCreated',
  WorkspaceDeleteResource_DEPRECATED: 'WorkspaceDeleteResource',
  WorkspaceDeleted: 'WorkspaceDeleted',
  WorkspaceInvitation: 'WorkspaceInvitation',
  WorkspaceMemberInvitedEvent: 'WorkspaceMemberInvitedEvent',
  WorkspaceMemberJoinedEvent: 'WorkspaceMemberJoinedEvent',
  WorkspaceMemberLeftEvent: 'WorkspaceMemberLeftEvent',
  WorkspaceMemberPendingEvent: 'WorkspaceMemberPendingEvent',
  WorkspaceMemberRemovedEvent: 'WorkspaceMemberRemovedEvent',
  WorkspaceNewResource_DEPRECATED: 'WorkspaceNewResource',
  WorkspaceResourceChanged_DEPRECATED: 'WorkspaceResourceChanged',
  WorkspaceResourceDownloaded_DEPRECATED: 'WorkspaceResourceDownloaded',
  WorkspaceUpdate: 'WorkspaceUpdate',
  WorkspacepMemberRejectedEvent: 'WorkspacepMemberRejectedEvent',
  MeetingEnded: 'MeetingEnded',
  MeetingInvitation: 'MeetingInvitation',
  MeetingChanged: 'MeetingChanged',
  HideMeetingInvitation: 'HideMeetingInvitation',
  TaggedResourceChanged: 'TaggedResourceChanged',
  ChannelResourceTagChanged: 'ChannelResourceTagChanged',
  ChannelResourceTagDeleted: 'ChannelResourceTagDeleted',
  NoteChanged: 'NoteChanged',
  NoteUpdated: 'NoteUpdated',
  Note: 'Note',
};

export const filterDisplayNotifications = notification =>
  notification.Type === notificationType.ChannelResourceLinked ||
  notification.Type === notificationType.ChannelLinkRenamed ||
  notification.Type === notificationType.ChannelResourceUnlinked ||
  notification.Type === notificationType.ChannelNewResource ||
  notification.Type === notificationType.WorkspaceNewResource_DEPRECATED ||
  notification.Type === notificationType.ChannelResourceWritabilityChanged ||
  notification.Type ===
    notificationType.GWorkspaceResourceWritabilityChanged_DEPRECATED ||
  notification.Type === notificationType.ChannelCollaboratorStatusChanged ||
  notification.Type ===
    notificationType.GWorkspaceCollaboratorStatusChanged_DEPRECATED ||
  notification.Type === notificationType.ChannelCollaboratorRemoved ||
  notification.Type ===
    notificationType.GWorkspaceCollaboratorRemoved_DEPRECATED ||
  notification.Type === notificationType.ChannelCollaboratorAdded ||
  notification.Type ===
    notificationType.GWorkspaceCollaboratorAdded_DEPRECATED ||
  notification.Type === notificationType.GWorkspaceCollaboratorApproved ||
  notification.Type === notificationType.GWorkspaceCollaboratorRejected ||
  notification.Type === notificationType.ChannelCreated ||
  notification.Type === notificationType.GWorkspaceCreated_DEPRECATED ||
  notification.Type === notificationType.ChannelRenamed ||
  notification.Type === notificationType.GWorkspaceRenamed_DEPRECATED ||
  notification.Type === notificationType.ChannelComment ||
  notification.Type === notificationType.SpaceComment_DEPRECATED ||
  notification.Type === notificationType.ResourceComment ||
  notification.Type === notificationType.GWorkspaceReward ||
  notification.Type === notificationType.ChannelResourceDownloaded ||
  notification.Type === notificationType.ReminderFired ||
  notification.Type === notificationType.Note;

export const isFileNotification = notification =>
  notification.Type === notificationType.ChannelResourceLinked ||
  notification.Type === notificationType.ChannelNewResource;

export const MEETING_NOTIFICATION = 'Meeting';

export default notificationType;
