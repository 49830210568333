import { rest } from 'msw';
import { getOtixoBackendApiUrl } from 'config/config';
import { getNoteByChannelIdUrl, getNoteHistoryUrl } from 'DataLayer/endpoints';

export const handlers = [
  // rest.get(
  //   `${getOtixoBackendApiUrl()}${getNoteByChannelIdUrl('*')}`,
  //   (req, res, ctx) => {
  //     return res(
  //       ctx.delay(300),
  //       ctx.status(200),
  //       ctx.json([
  //         {
  //           id: 'fb46b0a5-efcb-49d6-88df-066f2486a4cf',
  //           key: 'fb46b0a5-efcb-49d6-88df-066f2486a4cf',
  //           ownerId: '8a8181108da6f423018da79aa2550000',
  //           editorId: '8a8181108da6f423018da79aa2550000',
  //           workspaceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
  //           channelId: '246eb8e0-8390-4f1f-a90c-37a9b4d35cba',
  //           note: 'a note for jamie',
  //           scope: 'PRIVATE',
  //           createdAt: '2024-08-29T14:18:01.880944Z',
  //           activeEditorIds: [
  //             '8a8181108da6f423018da79aa2550000',
  //             '8a8181148df972b4018df98456cf0000',
  //           ],
  //         },
  //         {
  //           id: '1f1ecbe6-c1cb-4e04-af0e-da58757c2724',
  //           key: 'e9f2b135-097b-4737-a346-62a2b9a3c807',
  //           ownerId: '8a8181108da6f423018da79aa2550000',
  //           editorId: '8a8181108da6f423018da79aa2550000',
  //           workspaceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
  //           channelId: '246eb8e0-8390-4f1f-a90c-37a9b4d35cba',
  //           note: 'private',
  //           scope: 'PRIVATE',
  //           createdAt: '2024-08-29T14:18:01.880944Z',
  //           activeEditorIds: [
  //             '8a8181108da6f423018da79aa2550000',
  //             '8a8181148df972b4018df98456cf0000',
  //           ],
  //         },
  //         {
  //           id: 'bcc9173e-bbe8-4f63-a1f8-8770e883c774',
  //           key: 'e9f2b135-097b-4737-a346-62a2b9a3c807',
  //           ownerId: '8a8181108da6f423018da79aa2550000',
  //           editorId: '8a8181108da6f423018da79aa2550000',
  //           workspaceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
  //           channelId: '246eb8e0-8390-4f1f-a90c-37a9b4d35cba',
  //           note: 'jamie+feb13 note publicw \n\n jamie+feb13 note publicw \n\n jamie+feb13 note publicw \n\n jamie+feb13 note publicw \n\n jamie+feb13 note publicw \n\n jamie+feb13 note publicw \n\n jamie+feb13 note publicw \n\n jamie+feb13 note publicw \n\n jamie+feb13 note publicw \n\n jamie+feb13 note publicw \n\n jamie+feb13 note publicw \n\n jamie+feb13 note publicw \n\n jamie+feb13 note publicw \n\n jamie+feb13 note publicw \n\n ',
  //           scope: 'PUBLIC',
  //           createdAt: '2024-07-28T11:00:37.969026Z',
  //           activeEditorIds: null,
  //         },
  //         {
  //           id: 'e9f2b135-097b-4737-a346-62a2b9a3c807',
  //           key: 'e9f2b135-097b-4737-a346-62a2b9a3c807',
  //           ownerId: '8a8181148df972b4018df98456cf0000',
  //           editorId: '8a8181148df972b4018df98456cf0000',
  //           workspaceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
  //           channelId: '246eb8e0-8390-4f1f-a90c-37a9b4d35cba',
  //           note: 'jamie+mar1 note public',
  //           scope: 'PUBLIC',
  //           createdAt: '2024-06-28T14:01:36.757753Z',
  //           activeEditorIds: ['a', 'b'],
  //         },
  //       ])
  //     );
  //   }
  // ),
  // rest.get(
  //   `${getOtixoBackendApiUrl()}${getNoteHistoryUrl('*')}`,
  //   (req, res, ctx) => {
  //     return res(
  //       ctx.delay(300),
  //       ctx.status(200),
  //       ctx.json([
  //         {
  //           id: '1f1ecbe6-c1cb-4e04-af0e-da58757c2724',
  //           key: 'e9f2b135-097b-4737-a346-62a2b9a3c807',
  //           ownerId: '8a8181108da6f423018da79aa2550000',
  //           editorId: '8a8181108da6f423018da79aa2550000',
  //           workspaceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
  //           channelId: '246eb8e0-8390-4f1f-a90c-37a9b4d35cba',
  //           note: 'private3',
  //           scope: 'PRIVATE',
  //           createdAt: '2024-08-29T16:18:01.880944Z',
  //           activeEditorIds: [
  //             '8a8181108da6f423018da79aa2550000',
  //             '8a8181148df972b4018df98456cf0000',
  //           ],
  //         },
  //         {
  //           id: '1f1ecbe6-c1cb-4e04-af0e-da58757c2722',
  //           key: 'e9f2b135-097b-4737-a346-62a2b9a3c807',
  //           ownerId: '8a8181108da6f423018da79aa2550000',
  //           editorId: '8a8181108da6f423018da79aa2550000',
  //           workspaceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
  //           channelId: '246eb8e0-8390-4f1f-a90c-37a9b4d35cba',
  //           note: 'private2',
  //           scope: 'PRIVATE',
  //           createdAt: '2024-08-29T14:18:01.880944Z',
  //           activeEditorIds: [
  //             '8a8181108da6f423018da79aa2550000',
  //             '8a8181148df972b4018df98456cf0000',
  //           ],
  //         },
  //         {
  //           id: '1f1ecbe6-c1cb-4e04-af0e-da58757c2721',
  //           key: 'e9f2b135-097b-4737-a346-62a2b9a3c807',
  //           ownerId: '8a8181108da6f423018da79aa2550000',
  //           editorId: '8a8181108da6f423018da79aa2550000',
  //           workspaceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
  //           channelId: '246eb8e0-8390-4f1f-a90c-37a9b4d35cba',
  //           note: 'private1',
  //           scope: 'PRIVATE',
  //           createdAt: '2024-08-29T11:18:01.880944Z',
  //           activeEditorIds: [
  //             '8a8181108da6f423018da79aa2550000',
  //             '8a8181148df972b4018df98456cf0000',
  //           ],
  //         },
  //       ])
  //     );
  //   }
  // ),
  // // rest.put(
  // //   `${getOtixoBackendApiUrl()}${getNotePutUrl('*')}`,
  // //   (req, res, ctx) => {
  // //     return res(
  // //       ctx.delay(300),
  // //       ctx.status(200),
  // //       ctx.json({
  // //         id: '1',
  // //         userId: '8a8181108da6f423018da79aa2550000',
  // //         workspaceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
  // //         channelId: '246eb8e0-8390-4f1f-a90c-37a9b4d35cba',
  // //         note: '1',
  // //         scope: 'PRIVATE',
  // //         createdAt: '2024-08-29T07:40:58.162264Z',
  // //
  // //       })
  // //     );
  // //   }
  // // ),
  // rest.get(
  //   'https:dev.we.team/v3.0/MyNotifications?spaceId=246eb8e0-8390-4f1f-a90c-37a9b4d35cba&Limit=50&fields=imageheight',
  //   (req, res, ctx) => {
  //     return res(
  //       ctx.delay(300),
  //       ctx.status(200),
  //       ctx.json({
  //         Notifications: [
  //           {
  //             Type: 'Note',
  //             User: 'jamie+feb13@otixo.com',
  //             CreatedBy: {
  //               FirstName: 'j',
  //               UserID: 'jamie+mar1@otixo.com',
  //               LastName: 'c',
  //             },
  //             Note: {
  //               id: 'fb46b0a5-efcb-49d6-88df-066f2486a4cf',
  //               key: 'fb46b0a5-efcb-49d6-88df-066f2486a4cf',
  //               ownerId: '8a8181108da6f423018da79aa2550000',
  //               editorId: '8a8181108da6f423018da79aa2550000',
  //               workspaceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
  //               channelId: '246eb8e0-8390-4f1f-a90c-37a9b4d35cba',
  //               note: 'private',
  //               scope: 'PRIVATE',
  //               createdAt: '2024-08-29T14:18:01.880944Z',
  //               activeEditorIds: [
  //                 '8a8181108da6f423018da79aa2550000',
  //                 '8a8181148df972b4018df98456cf0000',
  //               ],
  //             },
  //             Message: 'j c hat den Channel verlassen',
  //             NotificationReadState: false,
  //             Title: 'a in Tag test',
  //             Id: 'jamie+feb13@otixo.com:89ce525b-06f3-4d8c-a250-4bbe0b26a39c',
  //             Starred: false,
  //             StarredTimestamp: null,
  //             NotificationTimestamp: 1722346664813,
  //             Workspace: {
  //               SpaceType: 'NORMAL',
  //               SpaceArchived: false,
  //               WorkspaceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
  //               WorkspaceName: 'Tag test',
  //               Id: '246eb8e0-8390-4f1f-a90c-37a9b4d35cba',
  //               Name: 'a',
  //             },
  //           },
  //           {
  //             Type: 'Note',
  //             User: 'jamie+feb13@otixo.com',
  //             CreatedBy: {
  //               FirstName: 'j',
  //               UserID: 'jamie+mar1@otixo.com',
  //               LastName: 'c',
  //             },
  //             Note: {
  //               id: 'X_fb46b0a5-efcb-49d6-88df-066f2486a4cf',
  //               userId: '8a8181108da6f423018da79aa2550000',
  //               workspaceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
  //               channelId: '246eb8e0-8390-4f1f-a90c-37a9b4d35cba',
  //               note: 'i am a **note** yeah',
  //               scope: 'PUBLIC',
  //               createdAt: '2024-08-27T13:03:15.558179Z',
  //             },
  //             Message: 'j c hat den Channel verlassen',
  //             NotificationReadState: false,
  //             Title: 'a in Tag test',
  //             Id: 'jamie+feb13@otixo.com:89ce525b-06f3-4d8c-a250-4bbe0b26a39b',
  //             Starred: false,
  //             StarredTimestamp: null,
  //             NotificationTimestamp: 1722346664813,
  //             Workspace: {
  //               SpaceType: 'NORMAL',
  //               SpaceArchived: false,
  //               WorkspaceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
  //               WorkspaceName: 'Tag test',
  //               Id: '246eb8e0-8390-4f1f-a90c-37a9b4d35cba',
  //               Name: 'a',
  //             },
  //           },
  //           {
  //             Type: 'ChannelCollaboratorStatusChanged',
  //             User: 'jamie+feb13@otixo.com',
  //             Collaborator: {
  //               Status: 'JOINED',
  //               Email: 'jamie+mar1@otixo.com',
  //               OldStatus: 'N/A',
  //               NewStatus: 'JOINED',
  //               Id: '71cc703a-4e78-11ef-88a8-0e7aba7d8a61',
  //               Name: 'j c',
  //             },
  //             CreatedBy: {
  //               FirstName: 'j',
  //               UserID: 'jamie+mar1@otixo.com',
  //               LastName: 'c',
  //             },
  //             Message: 'j c hat den Channel betreten',
  //             NotificationReadState: true,
  //             Title: 'a in Tag test',
  //             Id: 'jamie+feb13@otixo.com:aa99d218-0257-4506-a203-45ab6b6e6f51',
  //             Starred: false,
  //             StarredTimestamp: null,
  //             NotificationTimestamp: 1722346468558,
  //             Workspace: {
  //               SpaceType: 'NORMAL',
  //               SpaceArchived: false,
  //               WorkspaceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
  //               WorkspaceName: 'Tag test',
  //               Id: '246eb8e0-8390-4f1f-a90c-37a9b4d35cba',
  //               Name: 'a',
  //             },
  //           },
  //           {
  //             Type: 'ChannelCreated',
  //             User: 'jamie+feb13@otixo.com',
  //             CreatedBy: {
  //               FirstName: 'j*/',
  //               UserID: 'jamie+feb13@otixo.com',
  //               LastName: 'c',
  //             },
  //             NotificationReadState: true,
  //             Id: 'jamie+feb13@otixo.com:d4443e27-9970-4070-a85f-e3435e85d549',
  //             Starred: false,
  //             StarredTimestamp: null,
  //             NotificationTimestamp: 1722003663204,
  //             Workspace: {
  //               SpaceType: 'NORMAL',
  //               SpaceArchived: false,
  //               WorkspaceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
  //               WorkspaceName: 'Tag test',
  //               Id: '246eb8e0-8390-4f1f-a90c-37a9b4d35cba',
  //               Name: 'a',
  //             },
  //           },
  //         ],
  //         NextToken: null,
  //         HasOlderMessages: false,
  //         HasNewerMessages: false,
  //         LastRead: {},
  //       })
  //     );
  //   }
  // ),
];
